<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="offerbox" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="white" elevation="0">
            <v-btn icon dark color="primary" @click="closeBox">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-card-title class="title_text" c>{{property.property_name}} Offer</v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text color="primary" @click="offerbox = false"></v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-form ref="offer" class="mt-2">
            <v-card-text>
              <v-row align-center justify="center" fill-height>
                <v-col cols="12" sm="8" md="4">
                  <v-text-field
                    v-model="offer_form.client_name"
                    label="Client Name"
                    ref="price"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="offer_form.client_phone"
                    label="Client Phone"
                    ref="price"
                    :rules="[rules.required,rules.phone,rules.phone_length]"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="offer_form.client_email"
                    label="Client Email"
                    ref="price"
                    :rules="[rules.required,rules.email]"
                    outlined
                  ></v-text-field>

                  <v-text-field
                    v-model="offer_form.offer_price"
                    label="Offer price"
                    ref="price"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>

                  <v-select
                    v-model="offer_form.payment_plan"
                    :items="pay_plan"
                    label="Payment Option"
                    outlined
                    ref="plan"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="green darken-1" text @click="closeBox">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :loading="apiloading"
                :disabled="apiloading"
                color="success"
                width="120"
                @click.prevent="makeOffer()"
              >Send</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <success text="Offer Sent Successfully"/>
    </v-row>
  </div>
</template>
<script>
import EventBus from "@/Services/Event";
import moment from "moment";
import { loadingMixin } from "@/mixins/Loading";
export default {
  inject: ["Bus"],
  mixins: [loadingMixin],
  props: ["property"],
  data() {
    return {
      pay_plan: ["Outright", "Mortgage"],
      title: "",
      offerbox: false,
      offer_form: {
        client_name: "",
        client_phone: "",
        client_email: "",
        offer_price: "",
        payment_plan: "",
        agentid: "",
        propertyid: ""
      },
      rules: {
        required: value => !!value || "Required",
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail address.";
            },
        phone: value=>{
            // const p = /^[0-9]\d{2}-\d{3}-\d{4}$/;
               const p = /^[0-9]/;
            return p.test(value) || "Invalid phone number";
        },
        phone_length:value=>{
            const l = 10;
            return value > 10 || "Invalid phone length"
        }
  }
    };
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    makeOffer() {
       this.offer_form.propertyid = this.property.id;
        this.offer_form.agentid = this.$store.state.user_module.user.id
      if(this.$refs.offer.validate()){
          this.$store.dispatch("propertyOfferAction",this.offer_form).then((res)=>{
              this.Bus.$emit("show_success",true);
              this.closeBox();
             
          }).catch((err)=>{

          })
      }else{
          return
      }
    },
    closeBox() {
       this.offer_form={}
      this.offerbox = false;
    }
  },
  created() {
   
    this.Bus.$on("open-offer-box", t => {
      this.offerbox = t;
    });
  }
};
</script>
<style scoped>
.title_text {
  color: #000;
  font-size: 12px;
}
</style>