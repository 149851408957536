<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
          <div style="position:absolute;top:0;width:100%;height:150px;background-color:#fff;z-index:2000">
              <v-btn icon dark @click="dialog = false" color="secondary">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
          </div>
        <!-- <v-toolbar  color="white" height="150">
                <div style="margin-top:-90px;display:flex;justify-content:flex-start; height:60px">
                     <v-btn icon dark @click="dialog = false" color="secondary">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title style="padding-top:10px">Affordability Test</v-toolbar-title>
                        
                </div>
        </v-toolbar> -->
        
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
      inject:["Bus"],
       props:{
        property:{type:Object}
    },
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },
    created(){
        this.Bus.$on("show_mortgage_modal",(t)=>{
            this.dialog = t;
        })

    }
  }
</script>