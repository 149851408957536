<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="inspectionbox" fullscreen hide-overlay transition="dialog-bottom-transition">

                <v-card>

                    <v-toolbar dark color="white" elevation="0">
                        <v-btn icon dark color="primary" @click="closeBox()">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-card-title class="title_text" c>{{property.property_name}} </v-card-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text color="primary" @click="inspectionbox = false"></v-btn>
                        </v-toolbar-items>
                    </v-toolbar>


                    <v-form ref="tourform">
                        <v-card-text>
                            <v-row justify="center">
                                <v-container class="remove_padding">
                                    <v-menu v-model="date1" :close-on-content-click="false" max-width="290">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field :value="computedDateFormattedMomentjs" clearable label="Date"
                                                readonly outlined ref="date" :rules="[rules.required]" v-on="on"
                                                @click:clear="date = null"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="tour_form.book_date" @change="date1 = false"></v-date-picker>
                                    </v-menu>
                                </v-container>

                            </v-row>
                            <v-row justify="center">
                                <v-container class="remove_padding">
                                    <v-menu ref="menu" v-model="time1" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="time" transition="scale-transition" offset-y
                                        max-width="290px" min-width="200px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="tour_form.book_time" label="Time" outlined ref="time"
                                                :rules="[rules.required]" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-time-picker v-if="time1" v-model="tour_form.book_time" full-width
                                            @click:minute="$refs.menu.save(time)"></v-time-picker>
                                    </v-menu>
                                </v-container>

                            </v-row>
                            <v-radio-group v-model="tour_form.medium" ref="medium" :rules="[rules.required]">
                                <label for="">Prefer Mode of Communication</label>
                                <v-radio v-for="(n,i) in tour_medium_option" :key="i" :label="n.label" :value="n.value">
                                </v-radio>
                            </v-radio-group>


                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="closeBox()">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" :loading="apiloading" :disabled="apiloading" color="success" width="120"
                                @click.prevent="sendTour()">Send</v-btn>
                        </v-card-actions>
                    </v-form>


                </v-card>
            </v-dialog>
             <success text="inspection Sent Successfully"/>
        </v-row>

    </div>
</template>
<script>
    import EventBus from '@/Services/Event'
    import moment from 'moment'
    import {
        loadingMixin
    } from '@/mixins/Loading'
    export default {
        inject: ["Bus"],
        mixins: [loadingMixin],
        props: ["property"],
        data() {
            return {
                tour_medium_option: [{
                            value: 'Instagram',
                            label: 'Instagram'
                        },
                        {
                            value: 'Whatsapp',
                            label: 'Whatsapp'
                        }
                    ],
                    date1: false,
                    time1: false,
                    time: null,
                    title: "",
                    inspectionbox: false,
                    tour_form: {
                        agentid: "",
                        propertyid: "",
                        developerid: "",
                        name: "",
                        email: "",
                        phone: "",
                        is_agent: 1,
                        medium: "",
                        book_date: '',
                        book_time: ''
                    },
                    rules: {
                        required: value => !!value || "Required.",
                        email: value => {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(value) || "Invalid e-mail address.";
                            },
                        phone: value=>{
                            const p = /^[2-9]\d{2}-\d{3}-\d{4}$/;
                            return p.test(value) || "Invalid phone number";
                        }
                    }
            }
        },
        computed: {
              computedDateFormattedMomentjs () {
        return this.tour_form.book_date ? moment(this.tour_form.book_date).format('ddd,Do MMMM,YYYY') : ''
      },
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            getDefaultValue(){
                      this.tour_form.agentid= this.$store.state.user_module.user.id
                        this.tour_form.propertyid=this.property.id
                        this.tour_form.developerid=this.property.developerid
                       this.tour_form.name=this.$store.state.user_module.user.name
                       this.tour_form.email=this.$store.state.user_module.user.email
                        this.tour_form.phone=this.$store.state.user_module.user.phone
            },

            sendTour() {
                  this.getDefaultValue();
                if(this.$refs.tourform.validate()){
                    this.$store.dispatch("propertyBookTourAction",this.tour_form).then((res)=>{
                        this.Bus.$emit("show_success",true);
                        this.closeBox();
                    })
                }else{
                    return;
                }
            },
            closeBox(){
                this.tour_form= {  },
                this.inspectionbox=false;
            }
        },
        created() {
            this.Bus.$on("open-inspection-box", (t) => {
                this.inspectionbox = t;
            })
        }
    }
</script>
<style scoped>
    .title_text {
        color: #000;
        font-size:13px;
    }
</style>