<template>
<div>
     <div v-if="!showMortgage">
       <div class="property_detail_navigation">
         <v-row>
           <v-col cols="6">
             <v-btn icon @click="goBack()" class="property_nav">
               <v-icon color="white" size="30" >mdi-arrow-left</v-icon>
             </v-btn>
           </v-col>
           <v-col cols="6">
             <v-btn icon  class="property_nav float-right" @click.prevent="isSavedProperty?'':savedProperty(property.id)" :loading="apiloading"
                    :disabled="apiloading">
               <v-icon color="white" size="30" >{{isSavedProperty?"mdi-bookmark":"mdi-bookmark-outline"}}</v-icon>
             </v-btn>
           </v-col>

         </v-row>

       </div>
      <v-carousel
              :continuous="false"
              :cycle="true"
              :show-arrows="false"
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              class="my-carousel"
              color="primary"
              height="300"
      >
        <v-carousel-item   >
          <div class="property_image" :style="{backgroundImage:'url('+property.property_cover_image +')'}">

          </div>
        </v-carousel-item>
        <v-carousel-item v-for="(item, i) in propertyphoto" :key="item.id" >
          <div class="property_image" :style="{backgroundImage:'url('+item.filename +')'}">

          </div>
        </v-carousel-item>
<!--        <v-carousel-item v-for="(item, i) in propertyphoto" :key="i" :src="item.filename"-->
<!--          reverse-transition="fade-transition" transition="fade-transition">-->

<!--        </v-carousel-item>-->
      </v-carousel>
      <!-- <v-container> -->
      <div>
        <v-card-title class="property-name " style="font-size:18px">
         <span class="title" style="line-height: 20px !important;margin-bottom:10px;" > {{property.property_name | shortNameBy30}}</span>
          <v-btn class="status-label float-right" style="font-size:10px"  depressed small text >{{property.property_status}}</v-btn>
        </v-card-title>
        <v-card-subtitle style="color: rgba(0,0,0,0.47);font-size: 14px;">
          {{property.property_city}} , {{property.property_state}}
        </v-card-subtitle>
      </div>
     <v-divider></v-divider>
<!--        featured          -->
       <v-card class="mx-auto" elevation="0">
        <v-row class="pl-3" style="color:rgba(0,0,0,0.47)">
         <v-col cols="3" class="text-center">
           <img src="../../assets/images/beds.png" class="nh-single-page-property-features-icon"><br>
           <span class="featured">
             {{property.property_bedrooms}} beds
           </span>

         </v-col>
         <v-col cols="3" class="text-center">
           <img src="../../assets/images/baths.png" class="nh-single-page-property-features-icon"><br>
           <span class="featured"> {{property.property_bathrooms}} baths</span>
         </v-col>
         <v-col cols="3" class="text-center">
           <img src="../../assets/images/garage.png" class="nh-single-page-property-features-icon"><br>
           <span class="featured">{{property.property_garages}} cars</span>
         </v-col>
         <v-col cols="3" class="text-center">
           <img src="../../assets/images/size.png" class="nh-single-page-property-features-icon"><br>
           <span class="featured"> {{property.property_size}} sqm</span>
         </v-col>

       </v-row>
        </v-card>
     <v-divider></v-divider>
<!--        price-->
      <div>
        <v-card-title class="property-name" >
          <span style="font-size:20px;font-family: 'Circular Std'">{{property.property_price |currency}} <span style="font-size: 10px;color: rgba(0,0,0,0.47)"> Asking Price</span></span>
          <v-spacer></v-spacer>
             <v-btn  color="secondary" width="30" height="30" style="min-width: 30px">
                  <v-icon size="20" color="white">mdi-share-variant</v-icon>
                </v-btn>
          <!-- <v-btn color="success" @click="showOfferDialog()">Make Offer</v-btn> -->
        </v-card-title>
      </div>
      <v-divider></v-divider>
<!--       posted by-->
        <v-card class="mx-auto" style="margin:30px 0" elevation="0" v-if="property.user">
          <v-subheader style="height: 25px;padding-top:5px;color: #2b2b2b;" >Posted By</v-subheader>
          <profile-list-card :data="property.user" :show_subtitle="true" icon="mdi-chevron-right"></profile-list-card>
        </v-card>
<!--     description-->
      <v-card style="padding:10px;margin:30px 0" class="mx-auto"  elevation="0">
        <v-subheader style="height: 25px;padding-left:10px;padding-top:5px;color: #2b2b2b">DESCRIPTION</v-subheader>
        <div v-if="show_more_desc">
          <p style="font-size:12px; color:rgba(0,0,0,0.47);padding-top:5px" class="pl-2" v-html="property.property_description"  ></p>
        <span @click="short_description()" style="font-size:12px;color:#0077F3;margin-top:-20px">show less</span>
        </div>
    
            
         <div style="font-size:12px; color:rgba(0,0,0,0.47);padding-top:5px" class="pl-2"  v-if="!show_more_desc">
           <span>
             <span v-html="show_espace_desc" ></span>
             <span @click="show_more_description()" style="font-size:12px;color:#0077F3; margin-top:-20px">show more</span>
            </span>
           <!-- <v-btn  text  @click="show_more_description()"><span style="font-size:10px">{{show_more_desc?'show less':'show more'}}</span></v-btn> -->
         </div>

      </v-card>
       <v-divider></v-divider>
<!--       amenities-->
      <v-card class="mx-auto" style="padding:10px;margin:30px 0"  elevation="0">
        <v-subheader style="height: 25px;padding-left:10px;padding-top:5px;color: #2b2b2b">AMENITIES & FEATURES</v-subheader>
        <div class="amenity-display">
          <div class="amenity" v-for="(c, i) in propertyamenity" :key="c.id">
              <span>  <v-icon color="green" size="18">mdi-checkbox-marked-circle-outline</v-icon></span>
            <span style="font-size: 10px;padding-top:7px;padding-left:5px">{{c.amenity_name}}</span>
          </div>
        </div>
      </v-card>
       <v-divider></v-divider>
      <v-card class="mx-auto p-10 mb-10" style="margin:30px 0"  elevation="0">
        <v-subheader style="height: 25px;padding-left:13px;padding-top:5px;color: #2b2b2b">UNITS AVAILABLE</v-subheader>
        <div class="text-center">
          <v-simple-table :dense="dense" :fixed-header="fixedHeader" >
            <template v-slot:default>
              <thead>
              <tr>
                <th style="text-align: center;font-size:10px">Status</th>
                <th style="font-size:10px">Type</th>
                <th style="font-size:10px">Price</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(b, i) in propertyprice" :key="i">
                <td>
                  <v-icon :color="b.is_sold == 0?'secondary':'accent'">mdi-circle-medium</v-icon>
                  <!--                  <v-chip v-if="b.is_sold == 0" class="ma-2" color="success">-->
                  <!--                  </v-chip>-->
                  <!--                  <v-chip v-else class="ma-2" color="red">Sold-->
                  <!--                  </v-chip>-->
                </td>
                <td>{{b.hometype}}</td>
                <td>{{b.price | easy_currency}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

      </v-card>
        <v-divider></v-divider>
       <!-- <v-card class="mx-auto p-10 mb-10"  elevation="0" id="tour_card">
         <v-subheader style="height: 25px;padding-top:5px;color: #2b2b2b">Go Tour This Property</v-subheader>
         <property-tour-form :property_id="property.id"></property-tour-form>
       </v-card>
       <v-divider></v-divider> -->
       <!-- <v-card class="mx-auto p-10 mb-10"  elevation="0">
         <v-subheader style="height: 25px;padding-top:5px;color: #2b2b2b">CHECK MORTGAGE AFFORDABILITY</v-subheader>
         <v-container>
            <p style="font-size:12px; color:rgba(0,0,0,0.47);padding-top:5px;text-align:justify">
           Calculate the home loan your client qualifies for, and how much he/she can expect to pay back monthly should they decide to purchase via mortgage.
         </p>
         <div class="text-center" style="margin-top:20px">
                     <v-btn   color="secondary" width="250" @click="ShowMortgageDialog()" ><span style="color:#fff">Schedule Tour</span></v-btn>
                </div>
         </v-container>
        
       </v-card> -->

        <v-bottom-navigation app :fixed=true  >
            <!-- <v-row justify="center"> -->
            <span v-if="!isAuthUserProperty" style=" display:flex;justify-content: space-evenly;margin:0 15px">
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn @click="showOfferBottomModal()" color="secondary" class="offer_btn" large dark style=" border-radius: 5px;color: #fff !important;">Submit an Offer</v-btn>
                </div>
                <div style="height: 40px; width:50% ;padding:8px;">
                    <v-btn @click="showTourBottomModal()"  class="inspection_btn" style=" border-radius: 5px;color: #379392 !important;background-color: #eff6f5 !important;">Schedule Tour</v-btn>
                </div>
            </span>
            <template v-if="isAuthUserProperty">
                    <v-btn block width="250" @click="openBottomSheetMenu()" color="secondary" class="offer_btn" style=" border-radius: 5px;color: #379392 !important;background-color: #eff6f5 !important; margin: 8px 0">View Data</v-btn>
            </template>
            <!-- </v-row> -->

        </v-bottom-navigation>

             <v-bottom-sheet v-model="offer_modal" >
                 <v-sheet  height="300px">
         

                     <div class="text-center closeSheetBtnDiv">
                         <v-btn class="myBottomSheetBtn" icon dark @click="closeOfferModal()"
                         ><v-icon color="white">mdi-close</v-icon>  </v-btn>
                     </div>
                         <v-row justify="center">
                                <v-form ref="offerform">
                             <v-text-field v-model="offer_form.offer_price" label="Offer price" ref="price"
                                           :rules="[rules.required]" outlined name="price"></v-text-field>
                             <v-select v-model="offer_form.payment_plan" :items="pay_plan" label="Payment Option" outlined
                                       ref="payment_plan" :rules="[rules.required]"></v-select>

                             <v-row justify="center" style="padding:0 20px">
                                 <v-btn color="green darken-1" text @click="closeOfferModal()">Cancel</v-btn>
                                 <v-btn type="submit" :loading="apiloading" :disabled="apiloading" color="secondary" width="120"
                                        @click.prevent="sendOffer()"><span style="color:#fff">Send</span></v-btn>
                             </v-row>
                      

                         </v-form>
                         </v-row>

                 </v-sheet>
             </v-bottom-sheet>
             <v-bottom-sheet v-model="tour_modal" >
                 <v-sheet  height="400px" min-width="360" >
                     <div class="text-center closeSheetBtnDiv">
                         <v-btn class="myBottomSheetBtn" icon dark @click="closeTourModal()"
                         ><v-icon color="white">mdi-close</v-icon>  </v-btn>
                     </div>
                      <v-subheader style="height:18px;padding-top:5px;color: #2b2b2b">Go Tour This Property</v-subheader>
                        <property-tour-form :property_id="property.id"></property-tour-form>
                         
                             <!-- <v-row justify="center" style="padding:0 20px">
                             <v-btn type="submit" :loading="loading" :disabled="loading" color="secondary" width="120"
                                    @click.prevent="sendTour()"><span style="color:#fff">Send</span></v-btn>
                             </v-row> -->

                         <!-- </v-form> -->
                     <!-- </v-row> -->
                 </v-sheet>

             </v-bottom-sheet>
         <my-bottom-sheet height="200" :menu="bottom_sheet_menu" :property_slug="slug"></my-bottom-sheet>


      <Offer :property="property"/>
      <Inspection :property="property"/>



    </div>
      <!-- <Mortgage :property="property" /> -->
    
</div>
   
</template>
<script>

import EventBus from '../../Services/Event'
import Vue from 'vue'
import moment from 'moment'
import {sheetMixin} from "@/mixins/Sheet"
import Offer from '@/components/sheetmenu/Offer'
import Inspection from '@/components/sheetmenu/Inspection'
import Mortgage from '@/components/propertyview/Mortgage'
import toast from "../../Services/ToastService";
import {loadingMixin} from "../../mixins/Loading";
export default {
  inject:["Bus"],
  mixins:[sheetMixin,loadingMixin],
  components:{Offer,Inspection,Mortgage},
    data() {
        return {
            offer_modal:false,
            tour_modal:false,
            show_more_desc:false,
            pro_desc:'',
            bottom_sheet_menu:[
                {text:"Price Offers",icon:"mdi-calendar-clock",link:"property_offer_list"},
                {text:"Tour Schedules",icon:"mdi-book-open-outline",link:"property_inspection_list"},
                // {text:"Edit Property",icon:"mdi-home-edit-outline",link:"add_property_two"},

            ],
            offer_price:'',
          test_img: "https://randomuser.me/api/portraits/women/81.jpg",
          showMortgage:false,
          pageTitle:"Details",
          // sheet:false,
          date1:false,
          time1:false,
          time:null,
            slug:this.$route.params.slug,
             dense: false,
            fixedHeader: true,
            collectionmodal:false,

            pay_plan:['Outright','Mortgage'],
            submitted: false,
            loading:false,
            tour_medium_option:[
                {value:'Instagram',label:'Instagram'},
                {value:'Whatsapp',label:'Whatsapp'}
            ],
            offer_form:{
              offer_price:'',
              payment_plan:'',
              agentid: this.$store.state.user_module.user.id,
              propertyid:this.$store.state.property_module.property.id,

            },
             daysInMonth:[],
            tour_form:{
                 agentid: this.$store.state.user_module.user.id,
                propertyid:this.property_id,
                name:this.$store.state.user_module.user.firstname,
                email:this.$store.state.user_module.user.email,
                phone:this.$store.state.user_module.user.phone,
                book_date:moment().format('YYYY-mm-DD'),
                medium:'In Person',
                book_time:'9:00'
            
            },

            favourite_icon:'mdi-heart-outline',
            rules: {
                required: value => !!value || "Required.",
                counter: value => value.length <= 20 || "Max 20 characters",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail address.";
                }
            }


        }
    },
    computed: {
      isAuthUserProperty(){
         return this.checkIfPropertyIsForAuthUser(this.property.user_id)
      },
        isSavedProperty(){
            return this.checkIfPropertyIsSaved(this.property.id)
        },
      property(){
       
        let p =this.$store.state.property_module.property;

           this.pageTitle = p.property_name;
           this.pro_desc=p.property_description

        return p;
      },
      propertyphoto(){
        return this.property.propertyphoto
      },
      propertyamenity(){
       return this.property.amenity
      },
      propertyprice(){
        return this.property.propertyprice
      },
      computedDateFormattedMomentjs () {
        return this.tour_form.date ? moment(this.tour_form.date).format('ddd,Do MMMM,YYYY') : ''
      },
      show_espace_desc(){
        if(this.pro_desc !== undefined)return this.pro_desc.slice(0,200)+'.....';
      }
        
    },
    watch:{
      '$route'(to, from){
        this.slug = to.params.slug
      },
      // offer_price:function(val) {
      //     let v = this.currencyFormat(val)
      //     Vue.nextTick(() => (this.offer_price = v));
      // }
    },
    methods: {
          ShowMortgageDialog(){
              this.Bus.$emit("show_mortgage_modal",true)
          },
         generateDaysInMonths() {
            let startdate = moment().startOf('month');
            let today = moment(new Date());
            let endmonth = moment().endOf('month');
            let diff = endmonth.diff(today,'days')+1;
            // for(let i = 0; i < today.daysInMonth();i++){
            for(let i = 0; i < diff;i++){
                let newDay = today.clone().add(i,'days');
                this.daysInMonth.push({text:newDay.format('dddd DD MMMM YYYY'),value:newDay.format('YYYY-mm-DD')})
            }
        },
      show_more_description(){

         this.show_more_desc = true
      },
      short_description(){
         this.show_more_desc = false
      },
        currencyFormat(v) {
            return Number(v).toLocaleString("en");
        },
      goBack(){
        this.$router.go(-1);
      },
      showDetails(){
        this.$store.dispatch("resetPropertyDetailAction");
        this.$store.dispatch("viewPropertyDetailAction",this.slug)
      },
      resetForm (form) {
         let msg= form+'_errorMessages'
         let err = form+'_hasErrors'
          err = false
          msg =[]

        Object.keys(form).forEach(f => {
          this.$refs[f].reset()
        })
      },
      validateForm(form){
        let err = form+'_hasErrors'
        err = false

        Object.keys(form).forEach(f => {
          if (!form[f]) err = true
          this.$refs[f].validate(true)
        })
        if(err){
          return false
        }else{
          return true
        }
      },
        showOfferBottomModal(){

          this.offer_modal=true;
        },
        closeOfferModal(){
            this.offer_modal=false;
            this.$refs.offerform.reset()
        },
        showTourBottomModal(){

          this.tour_modal=true
          // window.location.href = "#tour_card"
        },
        openBottomSheetMenu(){
          this.Bus.$emit('open-bottom-sheet',true);
        },
        closeTourModal(){
            this.tour_modal=false
            this.$refs.tourform.reset()
        },
      sendOffer(){
        if(!this.$refs.offerform.validate()){
        }else{
            this.offer_form.agentid = this.$store.state.user_module.user.id
                this.offer_form.propertyid = this.$store.state.property_module.property.id
            this.$store.dispatch("propertyOfferAction", this.offer_form).then(res=>{
               this.closeOfferModal();
               toast.success('Offer Sent Successfully');

            })
        }


      },
      sendTour(){
         if(!this.$refs.tourform.validate()){
            return;
          }
              this.tour_form.propertyid=this.$store.state.property_module.property.id
             this.$store.dispatch("propertyBookTourAction", this.tour_form).then(res=>{
            this.closeTourModal();
            toast.success("The property agent is will back to you")

             })
          
      },
        savedProperty(propertyid){
            let user_id = this.$store.getters.userid
            let form = {user:user_id,property:propertyid}
            this.$store.dispatch("addPropertyTofavouriteAction",form).then((res)=>{
                toast.success("Property added to your collection")
            })
        },
  },
  mounted(){
    
    this.slug =this.$route.params.slug
    this.showDetails();
    this.generateDaysInMonths();

          
        
}
}
</script>
<style >
@import url("../../assets/css/nh.css");
  .remove_padding{
    padding:0 12px 0 12px !important;
  }
  .v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
    bottom: -15px !important;
}
  .property_image{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
    height:300px;
    color:rgba(0,0,0,0.5);

  }
  .property_nav{
      font-weight: 500;
      opacity: 0.46;
      background-color: rgba(33, 33, 33,0.43);
      border-color: rgba(33, 33, 33,0.43);
  }
  .property_detail_navigation{
    position: absolute;
    width: 100%;
    padding:10px;
    /*top:12px;*/
    z-index: 1000;
    color:#fff;
    /*display: flex;*/
    /*justify-content: space-between;*/

  }
  .property-name{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .v-application .title {
    font-size: 18px !important;
    white-space: initial;
    font-size: 18px;
    font-family: "Circular Std" !important;
    width: 50%;
    
  }


  .status-label{
    background-color: rgba(0, 119, 243, 0.05);
    color:#0077F3 !important;
    font-size:10px;
    min-width:100px;
    overflow-wrap: break-word;

    min-height: 20px;
    white-space: initial;
    /*font-wi*/
  }
  .featured{
    font-size: 12px;
  }
  .amenity-display{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .amenity{
    width:50%;
    display: flex;
    padding:10px;
  }
  .inspection_btn{
    border-radius: 5px;
    color: #379392 !important;
    background-color: #E5E5E5 !important;
  }
.closeSheetBtnDiv{
    margin-bottom: 0px;

}
.myBottomSheetBtn{
    width:50px;
    height: 50px;
    top:-20px;
    background-color: #379392 !important;
    border-color: #379392 !important;
    border-radius: 50%;
}
  </style>
