<template>
    <div>

        <search-area :title="display_offer_number" height="0" ></search-area>
        <template v-if="!isEmpty">
        <v-card elevation="0" v-for="(offer,i) in offers" :key="offer.id" class="my-2">
            <v-container>
            <div class="price_div" style="display:flex; justify-content: space-between">
                <span style="padding:5px; margin-left:3px">
                    {{offer.offer_price | easy_currency}}
                </span>
                        <span style="padding:5px; margin-right: 3px">
                        {{offer.payment_plan}}
                </span>
            </div>
            <v-list two-line subheader>
                <v-list-item>
                    <v-list-item-avatar class="avatar" >
                        <div class="profile_img" :style="{ backgroundImage: 'url(' + offer.agent.photo + ')' }" v-if="offer.agent.photo">
                            <v-icon size="25" :color="checkIfUserIsAFriend(offer.agentid)?'secondary': 'info'" class="status-icon">mdi-circle-medium</v-icon>
                        </div>
                        <!--                        <img src="https://randomuser.me/api/portraits/women/81.jpg" />-->
                        <!--                        <p>G</p>-->
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="title" >{{offer.agent.firstname}} {{offer.agent.lastname}}</v-list-item-title>
                        <v-list-item-subtitle class="subtitle">{{offer.agent.job}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="location-subtitle" >{{offer.agent.city}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon :class="checkIfUserIsAFriend(offer.agentid)?'mytheme_active_btn_background':'mytheme_inactive_btn_background'" @click="goToUserProfile(offer.agent.slug)">
                            <v-icon size="25" :color="checkIfUserIsAFriend(offer.agentid)?'primary':'grey'">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            </v-container>
            <v-divider></v-divider>
        </v-card>
        </template>

        <empty-list v-if="isEmpty"></empty-list>
    </div>
</template>

<script>
    import {loadingMixin} from "../../../mixins/Loading";

    export default {
        name: "OfferList",
        mixins:[loadingMixin],
        data() {
            return {
                slug: this.$route.params.slug,
                offer_found:0,
                isEmpty:false
            }
        },
        watch: {
            '$route'(to, from) {
                this.slug = to.params.slug;
            }
        },
        computed: {
            display_offer_number(){
              return this.offer_found +" Offers Found"
            },
            isActive(){
                let id = this.$store.getters.userid;

                return this.checkIfUserIsAFriend(id);
            },
            offers() {
                let p = this.$store.state.property_module.property_offer_list;
                if(p !== undefined && p !== null){
                    this.offer_found = p.length;
                    if(p.length <1){
                        this.isEmpty = true;
                    }
                }
                return p;
            }
        },
        methods: {
            getOfferList() {
                this.$store.dispatch("getPropertyOfferListAction",this.slug)
            },
            goToUserProfile(slug) {

                this.$router.push({name:"connect_profile",params:{slug:slug}});
            }
        },
        created() {
            this.slug =this.$route.params.slug
            this.getOfferList();
        }
    }
</script>

<style scoped>
    .rm-container{
        padding:0 10px 0 10px;
    }
    .title{
        font-size: 15px !important;
        font-style: normal;
        font-family: "Circular Std" !important;
        line-height: 16px !important;
    }
    .subtitle{
        font-size: 10px;
        font-style: normal;
        font-weight: normal;
        line-height: 13px;
        color:rgba(0,0,0,0.47) !important;
    }
    .location-subtitle{
        font-weight: normal;
        font-size: 8px;
        line-height: 10px;
        padding-top:10px;
        color:rgba(0,0,0,0.47) !important;
    }
    .avatar{
        width:57px !important;
        height: 57px !important;
        min-width: 57px !important;
    }
    .v-avatar{
        border-radius: 0 !important;
    }
    .profile_img{
        width:57px;
        height: 57px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-blend-mode: normal;
        background-repeat: no-repeat;

    }
    .status-icon{
        position: relative;
        top:-20px;
        right:-20px;
        /*z-index:2000;*/
    }
    .price_div{
        height: 40px;
        /*color:#0077F3;*/
        /*background-color: rgba(0, 119, 243, 0.05);*/

    }
    .mytheme_active_btn_background{
        /*background-color: rgba(0, 117, 242, 0.05);*/
        background-color: rgba(0, 119, 243, 0.05);
    }
    .mytheme_inactive_btn_background{
        /*background-color: rgba(0, 117, 242, 0.05);*/
        background-color: #F8F8F8;
    }
</style>