<template>
    <div>
        <search-area :title="display_inspection_number" height="0" ></search-area>
        <template v-if="!isEmpty">
            <div class="outter" v-for="(inspection,i) in inspections" :key="inspection.id">
            <div class="property_img" :style="{ backgroundImage: 'url(' + inspection.property.property_cover_image + ')' }"></div>
            <div class="property_info">
                <div class="property_info_detail">
                    <v-chip class="ma-2 price" color="secondary" label >{{inspection.property.property_price | currency}}</v-chip>
                    <p class="property_title break-word">{{inspection.property.property_name | shortNameBy40}}</p>
                    <span class="property_location">{{inspection.property.property_city}}</span>
                    <v-divider style="margin:10px 25px 0 10px;"></v-divider>
                    <v-card-subtitle class="amenity_container pro_subtitle">
                        <template  class="amenity_template">
                            {{inspection.property.property_bedrooms}}
                            <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/beds.png')+')' }"></div>
                        </template>

                        <template class="amenity_template">
                            {{inspection.property.property_bathrooms}}
                            <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/baths.png')+')' }"></div>
                        </template>
                        <template class="amenity_template">
                            {{inspection.property.property_size}}
                            <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/size.png')+')' }"></div>
                        </template>
                        <!-- <span class="pro_subtitle"> {{property.property_bedrooms}} bd. {{property.property_bathrooms}} bth. {{property.property_size}}sm</span> -->
                    </v-card-subtitle>

                </div>
                <v-btn icon class="mytheme_btn_background" width="30" height="30" style="margin:15% 0" @click="viewDetail(property)">
                    <v-icon size="25" color="primary">mdi-chevron-right</v-icon>
                </v-btn>




            </div>

        </div>
        </template>
        <empty-list v-if="isEmpty"></empty-list>
    </div>
</template>

<script>
    export default {
        name: "PropertyInspectionList",
        data() {
            return {
                slug: this.$route.params.slug,
                inspection_found:0,
                isEmpty:false
            }
        },
        watch: {
            '$route'(to, from) {
                this.slug = to.params.slug;
            }
        },
        computed: {
            display_inspection_number(){
                return this.inspection_found + ' Found'
            },
            inspections() {
                let p = this.$store.state.property_module.property_inspection_list;
                if(p !== undefined && p !== null){
                    this.inspection_found = p.length;
                    if(p.length <1){
                        this.isEmpty = true;
                    }
                }
                return p;
            }
        },
        methods: {
            getInspectionList() {
                this.$store.dispatch("getPropertyInspectionListAction",this.slug)
            }
        },
        created() {
            this.slug =this.$route.params.slug
            this.getInspectionList();
        }
    }
</script>

<style scoped>

</style>